.mailing {
  & > .left {
    & > .text {
      text-align: center;
      @include text($PTSans, 15px, normal, $color1 , 19px, null);
      margin-bottom: 15px; } }
  & > .right {
    & > .wrap-btn {
      & > button {
        margin-bottom: 30px; } } } }

.mailing-and-input {
  & .mailing-and-input-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & .input-container {
      width: 330px; }
    & .input-container:last-child {
 } } }      //margin-bottom: 10px

@import "develop";
@import "normalize";
@import "fonts";
@import "vars";
@import "header";
@import "calendar/calendar";
@import "calendar/calendar-media";
@import "./block/block";
@import "./block/block-media";
@import "./holiday/holiday";
@import "./share/share";
@import "./mailing/mailing";
@import "./mailing/mailing-media";
@import "./block-sv/block-sv";
@import "./block-sv/block-sv-media";
@import "./calendar-result-name/calendar-result-name";
@import "./calendar-result-name/calendar-result-name-media";
@import "./name-not-found/name-not-found";
@import "./description/description";
@import "./description/description-media";
@import "./left-menu/left-menu";
@import "./modal/modal";
@import "./modal/modal-media";
@import "./note/note";
@import "./note/note-media";
@import "./notification/notification";
@import "./notification/notification-media";
@import "./note-history/note-history";
@import "./note-history/note-history-media";
@import "./cabinet/cabinet";
@import "./cabinet/cabinet-media";
@import "./enter-soc/enter-soc";
@import "./payment/payment";
@import "./payment/payment-media";
@import "./donations/donations";

@import "footer";
@import "datepicker";

body {
  margin: 0 auto;
  width: 100%;
  min-width: 410px;
  height: auto;
  min-height: 100%;
  background: url("../img/fon.png") no-repeat top;
  background-size: cover;

  position: relative;
  background-color: #cbe9e8;
  //&::before
  //  background: $gradientBg
  //  content: ""
  //  display: block
  //  height: 100%
  //  left: 0
  //  position: absolute
  //  top: 80vh
  //  width: 100%
  & > .main-wrapper {
    position: relative;
    margin: 0 auto;
    min-width: 410px;
    width: 410px;
    max-width: 768px;
    height: 100vh;
    & > .content {
      padding: 0 20px;
      margin-bottom: 180px; } } }

._mr {
  margin-bottom: 40px; }

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > .col {
    align-items: flex-start;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  //& > .col:last-child
  //  & > .holiday
 }  //    margin-bottom: 0

.title {
  @include title($OpenSans, 27px, 800, normal, $color1, 37px, null);
  margin-bottom: 13px; }

.wrap-btn, .btn-blue {
  text-align: center;
  margin-top: 20px;
  & button, & a {
    display: inline-block;
    outline: 0 !important;
    @include text($PTSans, 18px, bold, #ffffff, 23px, null);
    width: 290px;
    padding: 10px 0;
    text-align: center;
    //padding: 10px 78px
    border-radius: 10px;
    box-shadow: $boxShadow;
    background: $gradient1;
    text-transform: uppercase;
    transition: $transition;
    position: relative;
    z-index: 100;
    &:before, &:after {
      border-radius: inherit;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      z-index: -100;
      transition: $transition; }
    &:after {
      background: $gradient7; }
    &:active:after {
      opacity: 1; }
    &:hover:before {
      opacity: 1; }
    &:before {
      background: $gradient6; } }
  & a:hover {
    color: #fff;
    cursor: pointer; }

  & button {
    &.reg-btn {
      color: #3F6A9F;
      border: 2px solid #3F6A9F;
      background: #fff;
      margin-top: 20px;
      &:before, &:after, &:active:after, &:hover:before {
        background: #fff;
        color: #3F6A9F;
        opacity: 1; } } } }

.btn-blue {
  & button, & a {
    //padding: 10px 60px!important
    background: $gradient5 !important;
    &:before {
      background: $gradient8 !important; } } }

.btn-gray {
  & button {
    background: #C3C7CB;
    color: #969B9E;
    pointer-events: none;
    &:before {
      background: #C3C7CB; }
    &:hover {
      background: #C3C7CB; } } }

.btn-gold {
  background: linear-gradient(180deg, #F7D572 0%, #F1B42B 110.46%) !important;
  &:before {
    background: $gradient2-1 !important; }
  &:after {
    background: $gradient2-2 !important; }
  &:hover {
    background: $gradient2-1 !important;
    color: #fff;
    cursor: pointer; }
  &:active {
    background: $gradient2-2 !important; } }

.input-container {
  //width: 370px
  width: 310px;
  position: relative;
  & input, textarea, select {
    width: 100%;
    border: 1px solid $color2;
    border-radius: 10px;
    height: 40px;
    padding: 10px 15px;
    //text-transform: uppercase
    margin-bottom: 10px;
    resize: none;
    &::placeholder {
      color: $color1;
      font-size: 15px;
      line-height: 20px; } } }

.input-container._error {
  & input, textarea, select {
    border-color: $colorError !important;
    color: $colorError !important;
    &::placeholder {
      color: $colorError !important; } } }

input, textarea, select {
  outline: none; }

input:active, textarea:active, select:active {
  outline: none; }

.textarea-mini {
  height: 145px !important; }

.container-label {
  margin-bottom: 25px;
  @include text($OpenSans, 13px, normal, $color1, 15px, null);
  //display: block
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  height: 25px;
  display: flex;
  align-items: center;
  & span {
    font-weight: bold; }
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  & .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid $color2;
    border-radius: 3px;
    &:after {
      left: 9px;
      top: 3px;
      width: 8px;
      height: 13px;
      border: solid $color1;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); } } }

.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.container-label input:checked ~ .checkmark:after {
  display: block; }

input, select, textarea {
  color: $color1; }

.text {
  @include text($PTSans, 15px, normal, $color1, 19px, null); }

.title-ln {
  line-height: 30px;
  margin-bottom: 20px; }

.event {
  & .block, .holiday {
    margin-bottom: 30px; } }

a {
  text-decoration: none;
  color: inherit;
  transition: $transition;
  &:hover {
    color: $colorHover; }
  &:active {
    color: $colorActive; } }

.error {
  @include text($PTSans, 15px, bold, $colorError, 19px, null);
  padding: 0 10px;
  display: none;
  margin-bottom: 5px;
  &._open {
    display: inline-block; } }

.wrapp-service-message {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; }

.service-message {
  display: inline-block;
  padding: 55px 10px;
  width: 320px;
  text-align: center;
  @include text($OpenSans, 18px, bold, $color3, 21px, null);
  background: #FFFDF0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px; }

._save-password {
  padding: 0 20px;
  margin: 0 0 10px 0;
  & button {
    width: 100%; } }

._btn-s {
  margin: 0 0 10px 0; }

.list-options {
  //display: none
  display: block;
  position: absolute;
  z-index: 1000;
  background: #FFFFFF;
  border: 1px solid #59B2BE;
  box-sizing: border-box;
  border-radius: 10px;
  width: 310px;
  //max-height: 150px
  padding: 10px 10px 10px 20px;
  & > .scroll {
    max-height: 130px;
    overflow-y: auto;
    & > div {
      @include text($OpenSans, 15px, normal, $color3, 33px, null);
      cursor: pointer;
      padding: 0 5px 0 0; } } }

::-webkit-scrollbar-button {
  width: 5px;
  height: 0px; }

::-webkit-scrollbar-track {
  background-color: #ecedee;
  border-radius: 5px; }

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #6dc0c8; }

::-webkit-scrollbar-thumb:hover {
  background-color: #56999f; }

::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 0px; }

::-webkit-scrollbar {
  width: 4px; }

.name-choice {
  display: flex;
  flex-direction: column;
  width: 100%;
  &  .item {
    display: flex;
    align-items: center;
    &  .icon {
      margin-right: 20px;
      &  img {
        width: 30px;
        height: 30px;
        background-size: contain; } }
    & a {
      font-family: PT Sans, sans-serif;
      font-size: 21px;
      font-weight: 700;
      color: #3f6a9f;
      line-height: 42px;
      &:hover {
        color: #139bd0; }
      &:active {
        color: #595dbe; } } } }

@import "media";




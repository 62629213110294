.payment-wrapp {
  & > .payment {
    display: inline-block;
    width: 100%;
    background: #fff;
    //width: 370px
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    margin-bottom: 60px;
    & > .header-title {
      padding: 35px 40px 0 40px;
      text-align: center;
      @include title($OpenSans, 24px, 800, bold, #3F6A9F , 27px, null);
      margin-bottom: 24px; }
    & > .wrapp-payment-input {
      padding: 0 30px 20px 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #CBE9E8;
      & .input-container {
        width: 100%; } }
    & > .payment-methods {
      padding: 0 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: 1px solid #CBE9E8;
      margin-bottom: 30px;
      & > .methods {
        width: 300px;
        & > .method {
          padding: 20px 0;
          & > .container-label {
            margin-bottom: 0; } } } }
    & > .offer-wrapp {
      padding: 0 40px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      & > .offer {
        width: 300px;
        & > .container-label {
          margin-bottom: 0; } } } } }

@media only screen and (min-device-width: 768px) {
  .notification-way {
    padding: 0;
    width: auto;
    & .way {
      width: 100%; } } }







.calendar-content {
  & > .header {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    & > .wrap {
      width: 380px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .arrow {
        background-image: url("../img/calendar/array.svg");
        height: 27px;
        width: 15px;
        background-repeat: no-repeat;
        transition: $transition;
        transform: translateZ(0);
        cursor: pointer;
        &:hover {
          transform: translateZ(0);
          background-image: url("../img/calendar/array.svg");
          background-image: url("../img/calendar/array-hover.svg"); }
        &:active {
          transform: translateZ(0);
          background-image: url("../img/calendar/array-hover.svg");
          background-image: url("../img/calendar/array-active.svg"); }
        &._right {
          transform: rotate(180deg); } }
      &._wrap-description {
        justify-content: center; }
      & > .date {
        @include text($OpenSans, 24px, null, null, null, null);
        letter-spacing: -0.05em;
        cursor: pointer;
        & > .month {
          @include text(null, null, bold, $color1 , 20px, null);
          display: inline-block;
          border-right: 1px solid;
          margin-right: 5px;
          padding-right: 10px;
          transition: $transition;
          &:hover {
            color: $colorHover; }
          &:active {
            color: $colorActive; } }
        & > .year {
          color: $color2; } } } } }

._col-calendar {
  margin-top: 0; }

.calendar {
  width: 372px;
  margin-bottom: 40px;
  & > .row {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      &._today {
        border: 2px solid $color5;
        font-weight: bold;
        background: #FFFFFF; }
      &._choice {
        border: 2px solid #38767E;
        font-weight: bold;
        background: #FFFFFF; }
      &._holiday {
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        font-weight: bold;
        background-position: center;
        background-image: url("../img/calendar/Group.png"); }
      &:hover {
        cursor: pointer; } }
    & > .day {
      @include text($OpenSans, 15px, normal, $color1, 20px, -0.01em);
      text-transform: uppercase; }
    & > .month {
      @include text($OpenSans, 21px, normal, $color1, 29px, null);
      &._other-month {
        opacity: 0.5; } } } }

.overlay {
  content: "";
  background: black;
  position: fixed;
  //opacity: 0
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: $transition; }
.left-menu {
  //position: absolute
  position: fixed;
  top: 0;
  padding-top: 50px;
  z-index: 1000;
  left: 0;
  transition: $transition;
  &._close {
    left: -100%;
    transition: $transition; }
  & > .content {
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url("../img/block/bg_2.svg"), $gradient4;
    background-repeat: no-repeat;
    border-radius: 0 10px 10px 0;
    position: relative;
    z-index: 1000;
    width: 320px;
    //height: 630px
    & > .icon {
      text-align: center; }

    & > .nav {
      padding-left: 40px;
      & > ul {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        //margin-bottom: 30px
        & > li {
          @include text($OpenSans, 24px, bold, $color3, 42px, null);
          text-transform: uppercase;
          align-items: center;
          display: flex;
          & > a {
            transition: $transition;
            &:hover {
              color: $colorHover; }
            &:active {
              color: $colorActive; } }
          & > span {
            width: 23px;
            height: 23px;
            background: red;
            border-radius: 50%;
            color: #fff;
            font-size: 11px;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-left: 10px; } } }
      .left-menu-share {
        & > .text {
          @include text($OpenSans, 13px, normal, $color1, 15px, null);
          margin-bottom: 17px; } } } } }

#close-left-menu, #close-modal {
  position: absolute;
  //bottom: 0
  top: 10px;
  right: 10px;
  //right: -75px
  //width: 60px
  width: 48px;
  height: 48px;
  //height: 60px
  & > div {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
    //background: url("../img/footer/sprite.png") 0px 0px no-repeat
    background: url("../img/close-modal-btn.png") 0px 0px no-repeat;
    transition: $transition;
    cursor: pointer;
    //&:after, &:before
    //  @include after-before
    //&:before
    //  background: url("../img/footer/sprite.png") -60px 0px no-repeat
    //&:hover::before
    //  opacity: 1
    //  background: url("../img/footer/sprite.png") -60px 0px no-repeat
    //&:after
    //  background: url("../img/footer/sprite.png") -120px 0px no-repeat
    //&:active::after
    //  opacity: 1
 } }    //  background: url("../img/footer/sprite.png") -120px 0px no-repeat



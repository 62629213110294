.holiday {
  border-radius: 5px;
  width: 100%;
  max-width: 370px;
  min-height: 80px;
  display: flex;
  background: $gradient1;
  box-shadow: $boxShadow;
  margin-bottom: 20px;
  position: relative;
  & > .date {
    position: absolute;
    background: url("../img/block/Oval-date.svg");
    width: 66px;
    height: 66px;
    right: 13px;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text($PTSans, 18px, bold, #ffffff, 23px, -0.05em); }
  & > .left {
    background-image: url("../img/holidays/bg_white.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    padding: 0px 10px;
    & > .text {
      display: block;
      & > .num {
        text-align: center;
        @include text($PTSans, 33px, bold, #fff, 37px, null); }
      & > .month {
        @include text($PTSans, 13px, bold, #fff, 17px, null);
        text-transform: uppercase; } } }
  & > .right {
    border-radius: 0 5px 5px 0;
    background-image: url("../img/holidays/holidays.png");
    width: 78%;
    background-position: top;
    background-size: cover;
    & > .text {
      display: block;
      padding: 20px;
      @include text($PTSans, 18px, bold, #ffffff, 20px, -0.05em);
      //br
      //  display: none
      //white-space: nowrap
      //overflow: hidden
 } } }      //text-overflow: ellipsis


.save-holidays {
  position: fixed;
  background: #fff;
  padding: 20px 0 30px;
  bottom: 50px;
  width: 100%;
  & > .save {
    & > .wrap-btn {
      margin-top: 0; } } }






@media only screen and (min-device-width: 768px) {
  .mailing {
    max-width: 100%;
    display: flex;
    & > div {
      width: 50%; }
    & > .left {
      & > .text {
        text-align: left; } }
    & > .right {
      & > .wrap-btn {
        text-align: right;
        margin-top: 15px; } } }
  .mailing-and-input {
    display: block;
    & > div {
      width: auto;
      & .text {
        text-align: center; } } } }

.datepicker {
  width: 260px;
  //height: 257px
  background: #FFFFFF;
  border: 1px solid #59B2BE;
  border-radius: 10px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  & > .list-year {
    position: absolute;
    display: none;
    width: 100%;
    max-height: 195px;
    top: 40px;
    padding: 2px;
    background: white;
    & > .scroll {
      max-height: 188px;
      overflow-y: auto;
      padding-right: 3px;
      & > div {
        @include text($OpenSans, 12px, normal, #38767E, 16px, -0.05em);
        padding: 5px 20px;
        background: #CBE9E8;
        margin-bottom: 2px;
        cursor: pointer;
        &.now {
          font-weight: bold; } } } }
  & > .header {
    background: $color2;
    //border-radius: 10px 10px 0 0
    display: flex;
    padding: 12px 20px;
    line-height: 18px;
    & > .month {
      @include text($OpenSans, 18px, bold, #fff, 18px, -0.05em);
      border-right: 1px solid #fff;
      padding-right: 10px;
      margin-right: 9px; }
    & > .year {
      @include text($OpenSans, 18px, normal, #fff, 18px, -0.05em);
      position: relative;
      & > .triangle {
        position: absolute;
        display: inline-block;
        right: -15px;
        top: 7px;
        cursor: pointer; } }
    & > .nav {
      display: flex;
      margin-left: auto;
      & > img {
        cursor: pointer; } } }
  & > .day {
    background: #CBE9E8;
    display: flex;
    padding: 5px 16px;
    justify-content: space-between;
    & > div {
      @include text($OpenSans, 12px, 300, $color1, 16px, -0.01em);
      text-transform: uppercase;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center; } }
  & > .row {
    padding: 0 16px;
    & > .day {
      @include text($OpenSans, 12px, normal, $color1, 16px, -0.01em);
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      &._other-month {
        color: #B7D6D5; }
      &._today {
        border: 2px solid #d30522; }
      &._choice {
        border: 2px solid #38767E; } } } }


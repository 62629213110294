.enter-soc {
  display: flex;
  justify-content: space-between;
  & > a {
    position: relative;
    width: 40px;
    height: 40px;
    & > img {
      position: absolute;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
      top: 0;
      left: 0;
      &:hover {
        opacity: 0.9;
        width: 45px;
        height: 45px;
        top: -3px;
        left: -3px; }
      &:active {
        width: 40px;
        height: 40px;
        top: 0;
        left: 0; } } } }

.calendar-result-name {
  padding: 0;
  & > .title-sv {
    margin-bottom: 20px; }
  & ._wrap-calendar-content {
    width: 310px!important;
    margin-bottom: 10px;
    & > .name {
      @include text($OpenSans, 27px, 800, $color1, 37px, null);
      text-transform: uppercase; } } }

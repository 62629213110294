@media only screen and (min-device-width: 768px) {
  .block-sv {
    width: 100%;
    padding: 30px 60px;
    & > .text {
      margin-bottom: 25px; } }

  .input-wrapp-block-sv {
    & > input {
      margin-bottom: 10px; } } }




@media only screen and (min-device-width: 768px) {
  .cabinet {
    width: 100%;
    padding: 45px 85px 30px 85px;
    & .input-container {
      padding: 0; } }

  .cabinet-input-name,.cabinet-input-password {
    & > .row {
      & > .col:nth-last-child(2) {
        & > .input-container {
          & > input {
            margin-bottom: 0; } } } } }

  .cabinet-input-password {
    & > .container {
      & > .row {
        & > .col:nth-last-child(2) {
          & > .input-container {
            & > input {
              margin-bottom: 0; } } } } } } }





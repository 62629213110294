.share {
  & > .text {
    text-align: center;
    @include text($PTSans, 15px, normal, $color4, 19px, null);
    margin-bottom: 15px; }
  & > .icons {
    display: flex;
    justify-content: center; } }
.share-icons {
  display: flex;
  justify-content: space-between;
  width: 242px;
  & > a {
    position: relative;
    width: 40px;
    height: 40px;
    & > img {
      position: absolute;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
      top: 0;
      left: 0;
      &:hover {
        opacity: 0.9;
        width: 45px;
        height: 45px;
        top: -3px;
        left: -3px; }
      &:active {
        width: 40px;
        height: 40px;
        top: 0;
        left: 0; } } } }


footer {
  //width: 410px
  width: inherit;
  margin: 0 auto;
  position: relative; }
.footer {
  background: #F1F8F5;
  display: flex;
  border-top: 5px solid white;
  //padding: 0px 80px 0 115px
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: inherit;
  z-index: 1000;
  letter-spacing: -1px;
  height: 50px;
  border-bottom:   5px solid white;
  //&:before
  //  content: ''
  //  position: absolute
  //  bottom: -20px
  //  width: 100%
  //  background: white
  //  height: 5px
  & > .footer-block {
    @include text($PTSans, 17px, bold, $color1, 22px, -0.01em);
    text-transform: uppercase;
    width: 50%;
    display: flex;
    height: inherit;
    align-items: center;
    position: relative;
    &:hover {
      color: $colorHover; }
    &:active {
      color: $colorActive; }
    &._bg-color-3 {
      background: url("../img/block/bg_white.svg"), $gradient1;
      z-index: 1;
      &:before,&:after {
        border-radius: inherit;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        transition: $transition; }
      &:after {
        background: url("../img/block/bg_white.svg"), $gradient5; }
      &:active:after {
        opacity: 1; }
      &:hover:before {
        opacity: 1; }
      &:before {
        background: url("../img/block/bg_white.svg"), $gradient8; }
      & span {
        color: #FFFFFF; } }
    &._bg-color-2 {
      background: url("../img/block/bg_white.svg"), $gradient2;
      z-index: 1;
      &:before,&:after {
        border-radius: inherit;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        transition: $transition; }
      &:after {
          background: url("../img/block/bg_white.svg"), $gradient2-1; }
      &:active:after {
        opacity: 1; }
      &:hover:before {
        opacity: 1; }
      &:before {
        background: url("../img/block/bg_white.svg"), $gradient2-2; }
      & span {
        color: #FFFFFF; } }
    &._bg-color-1 {
      background: url("../img/block/bg_white.svg"), $gradient3;
      z-index: 1;
      &:before,&:after {
        border-radius: inherit;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        transition: $transition; }
      &:after {
        background: url("../img/block/bg_white.svg"), $gradient7; }
      &:active:after {
        opacity: 1; }
      &:hover:before {
        opacity: 1; }
      &:before {
        background: url("../img/block/bg_white.svg"), $gradient6; }
      & span {
        color: #FFFFFF; } }
    &._left {
      & > .pos {
        width: 100%;
        text-align: right;
        margin-right: 65px;
        & > a {
          width: 100px;
          display: inline-block;
          text-align: center;
          &:hover {
            cursor: pointer; } } } }

    &._right {
      & > .pos {
        width: 100%;
        text-align: left;
        margin-left: 65px;
        & > a {
          width: 100px;
          display: inline-block;
          text-align: center;
          &:hover {
            cursor: pointer; } } } } }

  & > .icon {
    position: relative;
    width: 1px;
    & > div {
      position: absolute;
      top: -53px;
      left: -40px;
      background-image: url("../img/footer/icon.svg");
      width: 80px;
      height: 80px;
      transition: $transition;
      z-index: 2;
      &:after, &:before {
        @include after-before; }
      &:before {
        background-image: url("../img/footer/icon-hover.svg"); }
      &:hover::before {
        opacity: 1;
        background-image: url("../img/footer/icon-hover.svg"); }
      &:after {
        background-image: url("../img/footer/icon-active.svg"); }
      &:active::after {
        opacity: 1;
        background-image: url("../img/footer/icon-active.svg"); } } } }







.block-sv {
  padding: 30px 30px;
  & > .text {
    @include text($OpenSans, 15px, normal, $color3, 20px, null);
    text-align: center;
    margin-bottom: 35px;
 } }    //word-break: break-all //правка
.title-sv {
  @include text($PTSans, 33px, bold, $color3, 45px, null);
  text-align: center;
  text-transform: uppercase; }

.input-wrapp-block-sv {
  margin-bottom: 20px;
  & .input-date {
    position: absolute;
    top: 10px;
    right: 20px;
    background: white;
    pointer-events: none; } }

.block-sv-wrapp-name {
  & > .title {
    @include title($OpenSans, 21px, 800,  200, $color1, 29px, null); }
  & .block-name {
    display: flex;
    width: 310px;
    justify-content: space-between;
    & > div {
      width: 50%;
      @include text($PTSans, 21px, bold, $color3, 42px, null); } } }



.modal {
  position: fixed;
  //position: absolute
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  display: inline-block;
  transition: $transition;
  //margin-bottom: 150px
  margin-bottom: 300px;
  //&:before
  //  content: ""
  //  background: black
  //  position: absolute
  //  opacity: 0.5
  //  width: 100%
  //  height: 100%
  //  left: 0
  //  top: 0
  //  z-index: 100
  &._close {
    transition: $transition;
    top: -100%; }
  & > .content {
    padding-top: 45px;
    padding-bottom: 50px;
    z-index: 101;
    position: absolute;
    left: 50%;
    top: 50px;
    margin-left: -175px;
    //z-index: 1000
    background-image: url("../img/block/bg_2.svg"), $gradient4;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 350px;
    & > .title-sv {
      margin-bottom: 15px;
      font-size: 27px;
      font-family: $OpenSans; }
    & > .day {
      @include text($OpenSans, 27px, bold, $color1, 37px, -0.05em);
      text-transform: uppercase;
      text-align: center; }
    & > .date {
      @include text($OpenSans, 27px, bold, $color1, 37px,null);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px; }
    & > .inputs {
      padding: 0 40px;
      margin-bottom: 20px;
      & > .input-container {
        width: 270px;
        margin-bottom: 20px; } }
    & > .modal-soc {
      padding: 0 40px;
      margin-bottom: 20px; }
    & > .text {
      text-align: center;
      padding: 0 37px;
      margin-bottom: 25px;
      @include text($PTSans, 15px, normal, $color4, 19px, null); }
    & > .close {
      position: absolute;
      top: 10px!important;
      right: 10px!important;
      &:hover {
        cursor: pointer; } }
    & ._text-footer {
      margin: 25px 0 25px 0; } } }


.note-send {
  & > .content {
    padding-bottom: 0;
    & .block {
      width: 330px;
      margin: 0 10px 10px 10px;
      & > img {
        position: absolute;
        top: 10px;
        right: 10px; }
      & > .date {
        position: absolute;
        background: url("../img/block/Oval-date.svg");
        width: 66px;
        height: 66px;
        right: 13px;
        top: -20px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text($PTSans, 18px, bold, #ffffff, 23px, -0.05em); } } } }

.remember-holiday {
  & > .content {
    padding-bottom: 0;
    & > .holiday {
      width: 330px;
      margin: 0 10px 0px 10px; }
    & ._text-footer {
      margin: 25px 0 25px 0; } } }

.modal-not-methods {
  margin-top: 30px;
  border-top: 1px solid #B7D6D5;
  padding: 30px 20px 0 20px;
  & .modal-not-methods-text {
    text-align: center;
    margin-bottom: 20px;
    @include text($PTSans, 15px, normal, #477076, 19px, -0.05em); } }


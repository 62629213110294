.block {
  background-image: url("../img/block/bg_2.svg"), $gradient4;
  background-repeat: no-repeat;
  width: 100%;
  //max-width: 370px
  position: relative;
  //min-height: 195px
  border-radius: 10px;
  box-shadow: $boxShadow;
  margin-bottom: 20px;
  background-position: top;
  & > .date {
    position: absolute;
    background: url("../img/block/Oval-date.svg");
    width: 66px;
    height: 66px;
    right: 13px;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text($PTSans, 18px, bold, #ffffff, 23px, -0.05em); }
  ._block-in-calendar {
    margin-bottom: 0;
    margin-top: 0; }
  &._sv {
    padding: 20px 32px 0 32px; }
  & > .number {
    position: absolute;
    background: url("../img/block/Oval-date.svg");
    width: 66px;
    height: 66px;
    right: 13px;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text($PTSans, 27px, bold, #ffffff, 35px, -0.05em); }
  & .profile {
    display: flex;
    align-items: center;
    &._sv {
      padding-bottom: 20px; }
    & > .icon {
      margin-right: 20px;
      overflow: hidden;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 2px solid #D30522;
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        border-radius: 50%; } }
    & > .name {
      width: 190px;
      @include text($PTSans, 21px, bold, $color3, 27px, null);
      text-decoration-line: underline;
      text-transform: uppercase;
      transition: $transition;
      //word-break: break-all
      &:hover {
        color: $colorHover; }
      &:active {
        color: $colorActive; }
      //br
 } }      //  display: none

  & > .info {
    & > .text {
      @include text($PTSans, 15px, normal, $color4, 19px, null);
      word-break: break-all;
      &._sv {
        padding-bottom: 25px; } } }
  &._search {
    background-image: url("../img/holidays/h-bg.png");
    padding: 25px 30px;
    margin-bottom: 0;
    background-size: cover;
    & > .text {
      text-align: right;
      @include  title($PTSans, 15px, bold, normal, $color3, 19px, null);
 } }      //margin-bottom: 25px

  &._main-holiday {
    background-image: url("../img/holidays/main-holiday.png");
    padding: 20px 75px 20px 30px;
    background-size: cover;
    background-position: center;
    & > .text {
      @include text($PTSans, 30px, bold, #fff, 30px, null);
      text-shadow: $boxShadow;
      word-break: break-all; } } }

.main-dates {
  margin-bottom: 15px;
  & > .dates {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & > .date {
      @include text($OpenSans, 15px, normal, $color3, 15px, null);
      margin-left: 5px;
      padding-left: 5px;
      border-left: 1px solid $color7;
      margin-bottom: 5px;
      &._date-description {
        color: $color1;
        &:hover {
          color: $colorHover; }
        &:active {
          color: $colorActive; } }
      &._date-check {
        font-weight: bold; } }
    & > .date:first-child {
      margin-left: 0;
      padding-left: 0;
      border-left: none; } } }

.link-block {
  display: contents; }








.description {
  margin-top: 105px;
  padding: 100px 30px 30px 30px;
  &._project {
    padding-top: 45px;
    margin-top: 0; }
  & > .description-profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > .wrapp-icon {
      display: flex;
      justify-content: center;
      & > .icon {
        margin-top: -180px;
        overflow: hidden;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 4px solid #D30522;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
          width: 140px;
          border-radius: 50%; } } }
    & > .title {
      text-align: center;
      color: $color3; }
    & > .text {
      @include text($OpenSans, 13px, normal, $color1, 21px, null);
      text-align: justify; } } }

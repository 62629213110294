@media only screen and (min-device-width: 768px) {
  body {
    .row {
      & > .col {
        width: calc(42% + 40px);
        justify-content: flex-start;
        //&._left
        //  justify-content: flex-start
        //&._right
 }        //  justify-content: flex-end
      & > .col:nth-child(even) {
        display: flex;
        justify-content: flex-end; } }
    & > .main-wrapper {
      width: 768px;
      & > .content {
        padding: 0; } } }
  ._save-password {
    padding: 0; }
  footer {
    width: 100%; }
  .donations-wrapp {
    width: 100%; }
  .textarea-mini {
    height: 180px!important; } }





.note {
  padding-bottom: 20px;
  & > .header {
    padding-top: 35px;
    background-image: url("../img/bg_white_note.svg"), $gradient2;
    border-radius: 10px 10px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-bottom: 20px;
    & > .title {
      text-align: center;
      @include text($OpenSans, 24px, bold, #fff, 27px, null);
      margin-bottom: 25px;
      text-transform: uppercase; }
    & > .wrapp-note-input {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      & select {
        border: 1px solid $color8;
        color: $color8; } }
    & > .wrapp-note-profile {
      justify-content: center;
      display: flex;
      //margin-bottom: 20px
      & > .profile {
        display: flex;
        align-items: center;
        & > .icon {
          margin-right: 20px;
          overflow: hidden;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: #FFFFFF;
          border: 2px solid #D30522;
          display: flex;
          align-items: center;
          justify-content: center;
          & > img {
            border-radius: 50%; } }
        & > .name {
          @include text($PTSans, 18px, bold, #fff, 23px, null);
          text-decoration-line: underline;
          text-transform: uppercase; } } } }
  & > .list-names {
    text-align: center;
    padding: 15px 30px 0 30px;
    margin-bottom: 40px;
    & > .name {
      border-bottom: 1px solid  rgba(188, 133, 7, 0.5);
      & > input {
        background: transparent;
        @include text($PTSans, 27px, normal, $color8, 50px, null);
        border: none;
        width: 100%;
        text-align: center; }
      &._gray-value {
        & > input::placeholder {
          color: #C2C2C2; } } } }

  & > .text-info {
    padding: 0 35px;
    text-align: center;
    @include text($PTSans, 13px, normal, $color1, 21px, null); }
  & > .note-price {
    border-top: 1px solid #CBE9E8;
    margin-top: 15px;
    display: flex;
    padding: 15px 30px 0 30px;
    & > div {
      flex: 1 1 auto; }
    & > .text {
      @include  text($PTSans, 15px, normal, $color1, 19px, null); }
    & > .price {
      @include  title($OpenSans, 24px, 800, normal, $color1, 33px, null);
      justify-content: flex-end;
      display: flex;
      flex-wrap: nowrap;
      & > span {
        margin-right: 5px; } } } }

.note {
  &.repose {
    & > .header {
      background-image: url("../img/bg_white_note.svg"), $gradient5;
      & > .wrapp-note-input {
        & select {
          border: 1px solid $color3;
          color: $color3; } } }
    & > .list-names {
      & > .name {
        border-bottom: 1px solid rgba(63, 124, 159, 0.5);
        & > input {
            background: transparent;
            color: $color3;
            border: none;
            width: 100%;
            text-align: center; } } } } }

.order-wrapp {
  z-index: 999;
  position: fixed;
  width: 100%;
  bottom: 50px;
  & > .order {
    padding: 25px 0 40px 0;
    background-image: url("../img/bg_white_note.svg");
    background-color: #38767E;
    background-size: contain;
    background-position: center;
    & > .wrap-btn {
      margin-top: 0; } } }






header {
  & > .header {
    line-height: 0;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 30px;
    & > .login {
      background-image: url("../img/header/ico-Login.svg");
      width: 50px;
      height: 52px;
      background-repeat: no-repeat;
      transition: $transition;
      position: relative;
      cursor: pointer;
      &:after, &:before {
        @include after-before; }
      &:before {
        background-image: url("../img/header/ico-Login-hover.svg"); }
      &:hover::before {
        opacity: 1;
        background-image: url("../img/header/ico-Login-hover.svg"); }
      &:after {
        background-image: url("../img/header/ico-Login-active.svg"); }
      &:active::after {
        opacity: 1;
        background-image: url("../img/header/ico-Login-active.svg"); } }
    & > .search {
      background-image: url("../img/header/ico-Search.svg");
      width: 52px;
      height: 52px;
      background-repeat: no-repeat;
      transition: $transition;
      position: relative;
      cursor: pointer;
      &:after, &:before {
        @include after-before; }
      &:before {
        background-image: url("../img/header/ico-Search-hover.svg"); }
      &:hover::before {
        opacity: 1;
        background-image: url("../img/header/ico-Search-hover.svg"); }
      &:after {
        background-image: url("../img/header/ico-Search-active.svg"); }
      &:active::after {
        opacity: 1;
        background-image: url("../img/header/ico-Search-active.svg"); } } } }

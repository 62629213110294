.cabinet {
  padding: 45px 30px 30px 30px;
  & > .title {
    text-align: center;
    color: $color3; }
  & .input-container {
    padding: 0 20px; } }

.cabinet-notif,.cabinet-input-password {
  & > .title {
    font-size: 21px; } }

.cabinet-notif {
  & > .notification-way {
    padding: 0;
    & > .row {
      & > .col {
        justify-content: flex-start; } } } }

.cabinet-footer {
  margin-top: 20px;
  & > .title {
    @include text($OpenSans, 13px, normal, $color1, 21px, null);
    text-transform: none;
    margin-bottom: 15px; } }

.cabinet-input-name,.cabinet-input-password {
  & > .row {
    & > .col {
      & > .input-container {
        & > input {
          margin-bottom: 20px; } } }
    & > .col:last-child {
      & > .input-container {
        & > input {
          margin-bottom: 0; } } } } }
.cabinet-input-password {
  & > .container {
    & > .row {
      & > .col {
        & > .input-container {
          & > input {
            margin-bottom: 20px; } } }
      & > .col:last-child {
        & > .input-container {
          & > input {
            margin-bottom: 0; } } } } } }


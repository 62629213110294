.donations-wrapp {
  width: 410px;
  margin: 0 auto;
  position: relative;
  & > .donations {
    background: white;
    display: flex;
    //padding: 0px 80px 0 115px
    align-items: center;
    position: fixed;
    bottom: 70px;
    z-index: 1000;
    letter-spacing: -1px;
    width: inherit;
    justify-content: center;
    & > .content {
      width: 410px;
      display: flex;
      padding: 20px 20px 20px 20px;
      & > div {
        flex: 1 1 auto; }
      & > .left {
        & .text {
          @include  text($PTSans, 15px, normal, $color1, 19px, null); } }
      & > .right {
        text-align: right;
        & .price {
          @include  title($OpenSans, 24px, 800, normal, $color1, 33px, null); } } } } }


.note-history {
  position: relative;

  background: white;
  & > .header {
    height: 55px;
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    &._health {
      background-image: url("../img/bg_white_note.svg"), $gradient2; }
    &._repose {
      background-image: url("../img/bg_white_note.svg"), $gradient3; }
    & > img {
      position: absolute;
      top: 15px;
      right: 15px; }
    & > .title {
      @include text($OpenSans, 21px, bold, #fff, 27px, null);
      margin-bottom: 25px;
      text-transform: uppercase; } }
  & > .profile {
    padding: 10px 45px 10px 45px;
    background: $color6;
    & > .icon {
      width: 70px;
      height: 70px; }
    & > .name {
      ё {}
      @include text($OpenSans, 18px, bold, $color3, 23px, null); } }
  & > .names {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 45px 10px 45px;
    & > div {
      @include text($PTSans, 18px, bold, $color3, 18px, null);
      margin-left: 5px;
      padding-left: 5px;
      border-left: 1px solid $color7;
      margin-bottom: 5px; }
    & > div:first-child {
      margin-left: 0;
      padding-left: 0;
      border-left: none; } }
  & > .footer-info {
    margin-bottom: 15px;
    display: flex;
    padding: 0 28px;
    align-items: center;
    & > .time {
      @include  title($PTSans, 13px, bold, normal, $color1, 21px, null);
      text-transform: none; }
    & > .price {
      @include  title($OpenSans, 24px, 800, normal, $color1, 33px, null);
      margin-left: auto; } } }


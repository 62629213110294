.notification {
  & > .text {
    text-align: center;
    margin-bottom: 20px; } }


.notification-way {
  width: 370px;
  padding: 0 30px;
  & .way {
    margin-bottom: 20px;
    width: 310px;
    display: flex;
    align-items: center;
    & > .icon {
      margin-right: 10px;
      width: 50px;
      height: 50px; }
    & > .text {
      @include text($OpenSans, 15px, normal, $color1, 21px, null);
      text-transform: uppercase; }
    & > .input-container {
      width: 170px;
      padding: 0;
      & > input {
        margin-bottom: 0; } }
    & > .switch {
      padding: 3px;
      width: 50px;
      height: 25px;
      border-radius: 15px;
      border: 1px solid $color2;
      margin-left: auto;
      background: #FFFFFF;
      display: flex;
      transition: $transition;
      &:hover {
        cursor: pointer; }
      &._off {
        justify-content: flex-start; }
      &._on {
        justify-content: flex-end; }
      & > .circle {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        transition: $transition;
        &._off {
          background: #C2C2C2; }
        &._on {
          background: $color9; } } } } }

$OpenSans: 'Open Sans', sans-serif;
$PTSans: 'PT Sans', sans-serif;

$color1: #38767E;
$color2: #59B2BE;
$color3: #3F6A9F;
$color4: #477076;
$color5: #D30522;
$color6: #F1F8F5;
$color7: #C2C2C2;
$color8: #BC8507;
$color9: #59C6BD;
$colorHover: #139BD0;
$colorActive: #595DBE;
$colorError: #D30522;

$gradientBg: linear-gradient(180deg, rgba(203, 233, 232, 0) 4.45%, #CBE9E8 20.23%);
$gradient1: linear-gradient(180deg, #59B2BE 0%, #59C6BD 100%);
$gradient2: linear-gradient(180deg, #F1B42B 0%, #F7D572 110.46%);
$gradient2-1: linear-gradient(180deg, #FADE4B 0%, #FDC80A 110.46%); //hover
$gradient2-2: linear-gradient(180deg, #ED8F01 0%, #FAB44B 110.46%); //active
$gradient3: linear-gradient(180deg, #1783B2 0%, #6AA7D4 110.46%);
$gradient4: linear-gradient(180deg, #ECFBFF 0%, #FFFFFF 99.91%);
$gradient5: linear-gradient(180deg, #3F6A9F 0%, #3BC0DE 100%);

$gradient6: linear-gradient(180deg, #73DDD5 0%, #69CAD7 100%); //hover

$gradient7: linear-gradient(180deg, #595DBE 0%, #5998C6 100%); //active

$gradient8: linear-gradient(180deg, #1990C2 0%, #8EC9F3 110.46%);


@mixin after-before {
    content: "";
    opacity: 0;
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }



$boxShadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

$transition: .2s ease all;

@mixin text ($ff, $fs, $fw, $col, $lh, $lt) {
  font-family: $ff;
  font-size: $fs;
  font-weight: $fw;
  color: $col;
  line-height: $lh;
  letter-spacing: $lt; }

@mixin title ($ff, $fs, $fw_s,  $fw, $col, $lh, $lt) {
  font-family: $ff;
  font-size: $fs;
  font-weight: $fw;
  color: $col;
  line-height: $lh;
  letter-spacing: $lt;
  text-transform: uppercase;
  & > span {
    font-weight: $fw_s; } }
